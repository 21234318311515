import React from 'react'
import Hero from '../Hero'

const Details = ({ sample }) => {
  const { name, id, average, label, order } = sample
  return (
    <div>
      <Hero
        title={name}
        secondary={`id: ${id} average: ${average} label: "${label}" order: ${order}`} />
    </div>)
}

export default Details
