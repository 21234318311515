import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { reducer as facebookReducer } from './facebook'
import { reducer as tastingReducer } from './tasting'
import { reducer as editReducer } from './edit'
import client from '../graphql/client'

const rootReducer = combineReducers({
  facebook: facebookReducer,
  edit: editReducer,
  tasting: tastingReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(thunk.withExtraArgument(client))
))

export default store
