import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getAttendeesById } from '../store/tasting'
import React from 'react'
import withLogin from './withLogin'

const mapStateToProps = (state, ownProps) => ({
  attendees: getAttendeesById(parseInt(ownProps.match.params.id), state)
})

export default (WrappedComponent) => {
  class HOC extends React.Component {
    componentDidMount () {
      if (!this.props.attendees) {
        this.props.getAttendees(this.props.match.params.id)
      }
    }
    render () {
      return <WrappedComponent {...this.props} />
    }
  }
  return withLogin(withRouter(connect(mapStateToProps)(HOC)))
}
