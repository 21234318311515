import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { connect } from 'react-redux'
import { cancelEditSection as cancelEditSectionAction, saveSection as saveSectionAction, EDIT_TYPES } from '../store/edit'
import Details from './Edit/TastingDetails'
import Sample from './Edit/Sample'

const mapToEditComponent = sectionName => {
  if ([EDIT_TYPES.NEW_TASTING, EDIT_TYPES.TASTING_DETAILS].includes(sectionName)) {
    return <Details />
  } else if ([EDIT_TYPES.NEW_SAMPLE, EDIT_TYPES.SAMPLE_DETAILS].includes(sectionName)) {
    return <Sample />
  } else {
    console.warn('Bad sectionName ', sectionName)
    return null
  }
}

class EditContainer extends React.Component {
  render () {
    const { isEditing, sectionName, cancelEditSection, saveSection, isSaving } = this.props
    if (!isEditing) return null
    return (
      <Dialog
        open={isEditing}
        onClose={this.handleClose}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>{sectionName}</DialogTitle>
        <DialogContent>
          {mapToEditComponent(sectionName)}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEditSection} color='primary' disabled={isSaving}>
            Cancel
          </Button>
          <Button onClick={saveSection} color='primary' disabled={isSaving}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ edit }) => ({
  isEditing: edit.isEditing,
  isSaving: edit.isSaving,
  id: edit.id,
  sectionName: edit.sectionName,
  originalData: edit.originalData,
  editedData: edit.editedData
})

const mapDispatchToProps = dispatch => ({
  cancelEditSection: () => dispatch(cancelEditSectionAction()),
  saveSection: () => dispatch(saveSectionAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditContainer)
