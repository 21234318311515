import React, { Component } from 'react'
import {
  HashRouter as Router,
  Route
} from 'react-router-dom'
import './App.css'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo-hooks'
import client from './graphql/client'
import store from './store/store'
import Home from './Pages/Home'
import Tasting from './Pages/Tasting'
import Sample from './Pages/Sample'
import Participant from './Pages/Participant'

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <Router>
            <div className='App'>
              <Route path='/tasting/:id/:navItem' exact component={Tasting} />
              <Route path='/tasting/:id' exact component={Tasting} />
              <Route path='/sample/:id' exact component={Sample} />
              <Route path='/sample/:id/:navItem' exact component={Sample} />
              <Route path='/participant/:id' exact component={Participant} />
              <Route path='/participant/:id/:navItem' exact component={Participant} />
              <Route exact path='/' component={Home} />
            </div>
          </Router>
        </ApolloProvider>
      </Provider>
    )
  }
}

export default App
