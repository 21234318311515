import React from 'react'
import { withRouter } from 'react-router-dom'
import CardContainer from './CardContainer'
import Card from './Card'
import { connect } from 'react-redux'

export class TastingCards extends React.Component {
  render () {
    const { tastings, history } = this.props
    return (
      <CardContainer>
        {tastings.map((tasting, index) =>
          <Card
            key={`tasting-${index}`}
            title={tasting.name}
            secondary={tasting.description}
            thumbnailText={tasting.name}
            primaryActionText={'View'}
            primaryOnClick={() => history.push(`/tasting/${tasting.id}`)}
          />)}
      </CardContainer>
    )
  }
}
export const Tastings = withRouter(TastingCards)
const mapStateToProps = state => ({ tastings: state.tasting.tastings })
export default connect(mapStateToProps)(withRouter(TastingCards))
