import React from 'react'
import withAttendees from './withAttendees'
import CardContainer from './CardContainer'
import Attendee from './Attendee'
import Hero from './Hero'
class Attendees extends React.Component {
  render () {
    const { attendees } = this.props
    if (!attendees) return null
    return (
      <React.Fragment>
        <Hero
          title={'Attendees'}
          secondary={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
        />
        <CardContainer>
          {attendees.map((attendee, index) => <Attendee key={index} attendee={attendee} />)}
        </CardContainer>
      </React.Fragment>
    )
  }
}

export default withAttendees(Attendees)
