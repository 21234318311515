const APP_INSTANCE = process.env.REACT_APP_INSTANCE

const getConfigs = () => {
  if (APP_INSTANCE === 'staging') {
    return {
      FACEBOOK_APP_ID: '292048651404698',
      API_URI: '/api/v1'
    }
  } else if (APP_INSTANCE === 'prod') {
    return {
      FACEBOOK_APP_ID: '142847233229079',
      API_URI: '/api/v1'
    }
  } else {
    return {
      FACEBOOK_APP_ID: '292048651404698',
      API_URI: '/api/v1'
    }
  }
}

export default getConfigs()
