import React from 'react'
import SubPage from './SubPage'
import Samples from './Samples'
import withTasting from './withTasting'
import Attendees from './Attendees'

class Detail extends React.Component {
  render () {
    const { tasting } = this.props

    if (!tasting) return null
    return (
      <SubPage tabs={['Samples', 'Attendees']} pageName='Tasting'>
        <Samples />
        <Attendees />
      </SubPage>
    )
  }
}

export default withTasting(Detail)
