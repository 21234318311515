import gql from 'graphql-tag'

export const SAMPLE_DETAIL_QUERY = gql`
query sample($id: ID!) {
  sample(id: $id) {
    id,
    name,
    label,
    average,
    order,
    notes {
      id,
      color,
      nose,
      taste,
      guess,
      rating 
    }
  }
}
`

export const PARTICIPANT_DETAIL_QUERY = gql`
query user($id: ID!) {
  user(id: $id) {
    id,
    name,
    tastings {
      id,
      name
    },
    notes {
      id,
      color,
      nose,
      taste,
      guess,
      rating 
    }
  }
}
`
