import React from 'react'
import Button from '@material-ui/core/Button'
import AccountBox from '@material-ui/icons/AccountBox'
import Avatar from '@material-ui/core/Avatar'
import { login as loginAction } from '../store/facebook'
import { connect } from 'react-redux'

class PersonIcon extends React.Component {
  render () {
    const { user, login } = this.props
    const { id, picture } = user

    if (!id) return <Button color='inherit' onClick={login}>Login</Button>
    if (!picture) return <AccountBox />
    return (
      <Avatar alt='Profile Picture' src={picture.url} />
    )
  }
}
const mapStateToProps = (state) => ({
  user: state.facebook.user
})

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(loginAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonIcon)
