import React from 'react'
import AttendeeNotes from './AttendeeNotes'
import Card from './Card'

class Attendee extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }
  render () {
    const { attendee } = this.props
    if (!attendee) return null
    return (
      <React.Fragment>
        <Card
          title={attendee.name}
          thumbnailText={attendee.name}
          image={attendee.profilePicture}
          secondary={`Completed tastings: ${attendee.notes.length}`}
          primaryActionText={'View'}
          primaryOnClick={() => this.setState({ open: true })}
        />
        <AttendeeNotes
          notes={attendee.notes}
          onClose={() => this.setState({ open: false })}
          open={this.state.open} />
      </React.Fragment>
    )
  }
}

export default Attendee
