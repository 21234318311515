import React from 'react'
import SubPage from '../SubPage'
import { useQuery } from 'react-apollo-hooks'
import { SAMPLE_DETAIL_QUERY } from '../../graphql/queries'
import Details from './Details'
import Notes from './Notes'

const SampleDetails = ({ sampleId }) => {
  const { data, error, loading } = useQuery(SAMPLE_DETAIL_QUERY, { variables: { id: sampleId } })
  if (error) {
    return <span>errored</span>
  }
  if (loading) {
    return <span>Loading...</span>
  }
  const { sample } = data
  return (
    <SubPage tabs={['Details', 'Notes']} pageName='sample'>
      <Details sample={sample} />
      <Notes sample={sample} />
    </SubPage>
  )
}
export default SampleDetails
