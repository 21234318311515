export const buildSVGString = ({ text, textFill, backgroundColor }) => `
<svg viewBox="0 0 288 225" height="225" width="288" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
  <style>
    text { fill:#${textFill};font-weight:bold;font-family:Arial, Helvetica, Open Sans, sans-serif, monospace; font-size:48pt }
    rect {
      fill: url(#header-shape-gradient) #fff;
      transition: fill .4s ease;
    }
  </style>
  <rect xmlns="http://www.w3.org/2000/svg" width="288" height="225" />
  <text x="144" y="130" text-anchor="middle">${text}</text>
  <defs>
    <linearGradient id="header-shape-gradient" x2="0.35" y2="1">
      <stop offset="0%" stop-color="#${backgroundColor}" />
      <stop offset="100%" stop-color="black" />
    </linearGradient>
  </defs>
</svg>`

export const buildDataURL = (svg) => {
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`
}
// todo: create a color code from the title for the default color
function hashCode (str) { // java String#hashCode
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

function intToRGB (i) {
  var c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

const getLetersFromTitle = (title) => {
  return title.split(' ').map(str => str.charAt(0).toUpperCase()).join('')
}

export default (string = '', extra = '') => {
  const text = getLetersFromTitle(string)
  const backgroundColor = intToRGB(hashCode(string + extra))
  return buildDataURL(buildSVGString({ text, textFill: 'FFFFFF', backgroundColor }))
}
