import React from 'react'
import Card from './Card'
import { editSection as editSectionAction, EDIT_TYPES } from '../store/edit'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class Sample extends React.Component {
  render () {
    const { sample, editSection, showSampleNames } = this.props
    const { id } = sample

    return (
      <Card
        title={showSampleNames ? sample.name : ''}
        thumbnailText={sample.label}
        primaryOnClick={() => this.props.history.push(`/sample/${id}`)}
        primaryActionText={'view'}
        secondaryOnClick={editSection}
        secondaryActionText={'edit'}
      />
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  editSection: () => dispatch(editSectionAction(EDIT_TYPES.SAMPLE_DETAILS, ownProps.tastingId, ownProps.sample.id))
})

export default withRouter(connect(null, mapDispatchToProps)(Sample))
