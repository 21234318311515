import { getTastingById, getSampleById, postSample, postTasting, putSample, putTasting } from './tasting'

import addDays from 'date-fns/add_days'

export const EDIT_SECTION = 'EDIT_SECTION' // open up edit
export const EDIT_SECTION_EDIT = 'EDIT_SECTION_EDIT' // update data
export const EDIT_SECTION_SAVE = 'EDIT_SECTION_SAVE' // trigger save to server
export const EDIT_SECTION_SAVE_SUCCESS = 'EDIT_SECTION_SAVE_SUCCESS' // trigger save to server
export const EDIT_SECTION_SAVE_FAILURE = 'EDIT_SECTION_SAVE_FAILURE' // trigger save to server
export const EDIT_SECTION_CANCEL = 'EDIT_SECTION_CANCEL' // exit edit section

export const EDIT_TYPES = {
  NEW_TASTING: 'Create new tasting',
  TASTING_DETAILS: 'Edit tasting details',
  NEW_SAMPLE: 'Create new sample',
  SAMPLE_DETAILS: 'Edit sample'
}

const getStartingData = (getState, sectionName, id, sampleId) => {
  if (EDIT_TYPES.NEW_TASTING === sectionName) {
    return {
      name: '',
      description: '',
      startDate: new Date(),
      endDate: addDays(new Date(), 30)
    }
  } else if (EDIT_TYPES.NEW_SAMPLE === sectionName) {
    return {
      name: '',
      label: ''
    }
  } else if (EDIT_TYPES.TASTING_DETAILS === sectionName) {
    return getTastingById(id, getState())
  } else if (EDIT_TYPES.SAMPLE_DETAILS === sectionName) {
    return getSampleById(id, sampleId, getState())
  }
}
export const editSection = (sectionName, id, sampleId) => (dispatch, getState) => {
  // get data from the tasting and copy into originalData & editedData data
  // trigger action that sets the editing and section flag
  const startingData = getStartingData(getState, sectionName, id, sampleId)

  dispatch({
    type: EDIT_SECTION,
    payload: {
      originalData: Object.assign({}, startingData),
      editedData: Object.assign({}, startingData),
      id,
      sectionName
    }
  })
}

export const cancelEditSection = () => ({
  type: EDIT_SECTION_CANCEL
})

const triggerSave = ({ editedData, sectionName, id, client }) => {
  switch (sectionName) {
    case EDIT_TYPES.NEW_SAMPLE:
      return postSample(editedData, id, client)
    case EDIT_TYPES.NEW_TASTING:
      return postTasting(editedData, client)
    case EDIT_TYPES.SAMPLE_DETAILS:
      return putSample(editedData, client)
    case EDIT_TYPES.TASTING_DETAILS:
      return putTasting(editedData, client)
    default:
      return Promise.reject(new Error('Failed Edit type is not found'))
  }
}

export const saveSection = () => (dispatch, getState, client) => {
  const { edit } = getState()
  const { editedData, sectionName, id } = edit

  dispatch({ type: EDIT_SECTION_SAVE })
  return triggerSave({ editedData, sectionName, id, client })
    .then((data) => {
      dispatch({
        type: EDIT_SECTION_SAVE_SUCCESS,
        payload: {
          rtnData: data,
          sectionName,
          id
        }
      })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: EDIT_SECTION_SAVE_FAILURE })
    })
}

export const updateEditSection = (key, value) => (dispatch, getState) => {
  const { edit } = getState()
  const { editedData } = edit

  const newData = {
    ...editedData,
    [key]: value
  }

  return dispatch({
    type: EDIT_SECTION_EDIT,
    payload: {
      editedData: newData
    }
  })
}

const initialState = {
  isEditing: false,
  isSaving: false,
  id: null, // this an be the sample or the tasting
  sectionName: '',
  originalData: null,
  editedData: null
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_SECTION:
      return Object.assign({}, state, {
        isEditing: true,
        id: payload.id,
        sectionName: payload.sectionName,
        originalData: payload.originalData,
        editedData: payload.editedData
      })
    case EDIT_SECTION_CANCEL:
      return Object.assign({}, state, {
        isEditing: false,
        id: null,
        sectionName: '',
        originalData: null,
        editedData: null
      })
    case EDIT_SECTION_EDIT:
      return Object.assign({}, state, {
        editedData: payload.editedData
      })
    case EDIT_SECTION_SAVE:
      return {
        ...state,
        isSaving: true
      }
    case EDIT_SECTION_SAVE_SUCCESS:
      return Object.assign({}, state, {
        isEditing: false,
        isSaving: false,
        id: null,
        sectionName: '',
        originalData: null,
        editedData: null
      })

    case EDIT_SECTION_SAVE_FAILURE:
      return {
        ...state,
        isSaving: false
      }
    default:
      return state
  }
}
