import React from 'react'
import Hero from '../Hero'
import NoteTable from '../Sample/NoteTable'

const Notes = ({ participant }) => {
  const { notes } = participant
  return (
    <div>
      <Hero title={'Notes'} />
      <NoteTable data={notes} />
    </div>)
}

export default Notes
