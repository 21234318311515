import React from 'react'
import CardMaterial from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Thumbnail from './Thumbnail'

const styles = theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9 // todo: figure out wtf this is
  },
  cardContent: {
    flexGrow: 1
  }
})

class Card extends React.Component {
  render () {
    const { classes, children, title, secondary, thumbnailText, primaryActionText, primaryOnClick, secondaryActionText, secondaryOnClick, image } = this.props
    const thumbnail = Thumbnail(thumbnailText, title + secondary)
    return (
      <Grid item xs={12} sm={6} lg={4} xl={3}>
        <CardMaterial className={classes.card}>
          <CardMedia
            className={classes.cardMedia}
            image={image || thumbnail}
            title='Image title' />
          {title && <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant='h5' component='h2'>{title}</Typography>
            <Typography>{secondary}</Typography>
          </CardContent>}
          {children}
          <CardActions>
            {primaryActionText && <Button size='small' color='primary' onClick={primaryOnClick}>{primaryActionText}</Button>}
            {secondaryActionText && <Button size='small' color='secondary' onClick={secondaryOnClick}>{secondaryActionText}</Button>}
          </CardActions>
        </CardMaterial>
      </Grid>
    )
  }
}

export default withStyles(styles)(Card)
