import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  }
})

const PROPERTY_NAME_BLACK_LIST = ['__typename']
const getColumnNames = (data) => Object.keys(data[0]).filter(propertyName => !PROPERTY_NAME_BLACK_LIST.includes(propertyName))

function NoteTable (props) {
  const { classes, data } = props
  const columnNames = getColumnNames(data)

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columnNames.map((name, index) => <TableCell key={index}>{name}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id}>
              {columnNames.map((name, index) => <TableCell key={index}>{row[name]}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

NoteTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NoteTable)
