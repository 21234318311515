import React from 'react'
import Hero from '../Hero'
import { Tastings as TastingCards } from '../TastingCards'

const Tastings = ({ participant }) => {
  const { tastings } = participant
  return (
    <div>
      <Hero
        title={'Tastings'} />
      <TastingCards tastings={tastings} />
    </div>)
}

export default Tastings
