import React from 'react'
import Header from '../Components/Header'
import EditContainer from '../Components/EditContainer'
import SampleDetails from '../Components/Sample/SampleDetails'
import { useQuery } from 'react-apollo-hooks'
import { SAMPLE_DETAIL_QUERY } from '../graphql/queries'

const Sample = (props) => {
  const sampleId = props.match.params.id
  const { data, error, loading } = useQuery(SAMPLE_DETAIL_QUERY, { variables: { id: sampleId } })
  if (error) {
    return <span>errored</span>
  }
  if (loading) {
    return <span>Loading...</span>
  }
  const { sample } = data
  return (
    <div>
      <Header detail title={sample ? sample.name : ''} />
      <SampleDetails sampleId={sample.id} />
      <EditContainer />
    </div>
  )
}

export default Sample
