import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

export default class extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  render () {
    const { notes, onClose, open } = this.props
    return (
      <Dialog
        fullWidth
        maxWidth='xl'
        onClose={onClose}
        open={open}>
        <DialogTitle>Notes</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bourbon</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Nose</TableCell>
                <TableCell>Taste</TableCell>
                <TableCell>Guess</TableCell>
                <TableCell align='right'>Rating</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notes.map(note => (
                <TableRow key={note.id}>
                  <TableCell>{note.sample.name}</TableCell>
                  <TableCell>{note.color}</TableCell>
                  <TableCell>{note.nose}</TableCell>
                  <TableCell>{note.taste}</TableCell>
                  <TableCell>{note.guess}</TableCell>
                  <TableCell align='right'>{note.rating}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    )
  }
}
