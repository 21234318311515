import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import LocalBar from '@material-ui/icons/LocalBar'
import AppBar from '@material-ui/core/AppBar'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PersonIcon from './PersonIcon'
import IconButton from '@material-ui/core/IconButton'
import Settings from '@material-ui/icons/Settings'
import { editSection as editSectionAction, EDIT_TYPES } from '../store/edit'

const styles = theme => ({
  appBar: {
    position: 'relative'
  },
  icon: {
    marginRight: theme.spacing.unit * 2
  },
  grow: {
    flexGrow: 1
  }
})

const LINK_BACK = '/'

class Header extends React.Component {
  render () {
    const { classes, detail, history, title, editSection } = this.props

    return (
      <AppBar position='static' className={classes.appBar}>
        <Toolbar>
          <IconButton color='inherit' onClick={() => history.push(LINK_BACK)}>
            {detail ? <ArrowBack className={classes.icon} /> : <LocalBar className={classes.icon} />}
          </IconButton>
          <Typography variant='h6' color='inherit' noWrap className={classes.grow}>{title}</Typography>
          {detail && <Settings onClick={editSection} className={classes.icon} />}
          <PersonIcon />
        </Toolbar>
      </AppBar>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  editSection: () => dispatch(editSectionAction(EDIT_TYPES.TASTING_DETAILS, ownProps.match.params.id))
})

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(Header)))
