/* global sessionStorage */
import store from './store'
import { getTastings } from './tasting'
import configs from '../configs'
const { FACEBOOK_APP_ID } = configs

export const SET_USER_INFO = 'SET_USER_INFO'

export const getFacebookUser = state => {
  return state.facebook.user
}

const handleFacebookLogin = (response) => {
  if (response.status === 'connected') {
    // The user is logged in and has authenticated your
    // app, and response.authResponse supplies
    // the user's ID, a valid access token, a signed
    // request, and the time the access token
    // and signed request each expire.
    var id = response.authResponse.userID
    const accessToken = response.authResponse.accessToken
    // var accessToken = response.authResponse.accessToken
    sessionStorage.setItem('accessToken', accessToken)
    store.dispatch({ type: SET_USER_INFO, payload: { id, accessToken } })
    store.dispatch(getTastings())
    store.dispatch(getUserInfo())
  } else if (response.status === 'authorization_expired') {
    // The user has signed into your application with
    // Facebook Login but must go through the login flow
    // again to renew data authorization. You might remind
    // the user they've used Facebook, or hide other options
    // to avoid duplicate account creation, but you should
    // collect a user gesture (e.g. click/touch) to launch the
    // login dialog so popup blocking is not triggered.
    store.dispatch(getTastings())
  } else if (response.status === 'not_authorized') {
    // The user hasn't authorized your application.  They
    // must click the Login button, or you must call FB.login
    // in response to a user gesture, to launch a login dialog.
    store.dispatch(getTastings())
  } else {
    // The user isn't logged in to Facebook. You can launch a
    // login dialog with a user gesture, but the user may have
    // to log in to Facebook before authorizing your application.
    store.dispatch(getTastings())
  }
}

/* actions */
export const login = () => dispatch => {
  window.FB.login(handleFacebookLogin)
}

export const getUserInfo = () => (dispatch, getState) => {
  const userId = getState().facebook.user.id
  if (!userId) throw new Error('should not call getUserInfo with out an id in to store')

  window.FB.api(
    `/${userId}/picture`,
    'GET',
    { redirect: 'false' },
    function (res) {
      const picture = res.data
      const userInfo = {
        id: userId,
        picture
      }
      dispatch({ type: SET_USER_INFO, payload: userInfo })
    }
  )
}

/* init */
export const initializeFacebook = () => {
  function FBScript (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0] // eslint-disable-line
    if (d.getElementById(id)) { return }
    js = d.createElement(s); js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  }

  FBScript(document, 'script', 'facebook-jssdk')

  window.fbAsyncInit = () => {
    window.FB.init({
      appId: FACEBOOK_APP_ID,
      cookie: true,
      autoLogAppEvents: true,
      version: 'v3.1'
    })
    window.FB.getLoginStatus(handleFacebookLogin)
  }
}

const initialState = {
  user: {}
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_INFO:
      return Object.assign({}, state, { user: Object.assign({}, state.user, payload) })
    default:
      return state
  }
}
