import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  heroUnit: {
    backgroundColor: theme.palette.background.paper
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4
  }
})

class Hero extends React.Component {
  render () {
    const { classes, title, secondary, primaryActionText, primaryOnClick, secondaryComponent } = this.props

    return (
      <div className={classes.heroUnit}>
        <div className={classes.heroContent}>
          <Typography component='h1' variant='h2' align='center' color='textPrimary' gutterBottom>{title}</Typography>
          <Typography variant='h6' align='center' color='textSecondary' paragraph>{secondary}</Typography>
          {primaryActionText &&
          <div className={classes.heroButtons}>
            <Grid container spacing={16} justify='center'>
              <Grid item>
                <Button onClick={primaryOnClick} variant='contained' color='primary'>
                  {primaryActionText}
                </Button>
              </Grid>
              {secondaryComponent &&
                <Grid item>
                  {secondaryComponent}
                </Grid>
              }
            </Grid>
          </div>}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Hero)
