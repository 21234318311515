import { connect } from 'react-redux'
import { updateEditSection } from '../../store/edit'
import React from 'react'

const mapStateToProps = ({ edit }) => ({
  editedData: edit.editedData
})

const mapDispatchToProps = dispatch => ({
  updateEditSection: (key, value) => dispatch(updateEditSection(key, value))
})

export default (WrappedComponent) => {
  class HOC extends React.Component {
    render () {
      return <WrappedComponent {...this.props} />
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(HOC)
}
