import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { getTastingById, getTasting } from '../store/tasting'
import React from 'react'
import withLogin from './withLogin'

const mapStateToProps = (state, ownProps) => ({
  loading: state.tasting.tastingLoading,
  tasting: getTastingById(parseInt(ownProps.match.params.id), state)
})

const mapDispatchToProps = (dispatch) => ({
  getTasting: id => dispatch(getTasting(id))
})

let fetching = false

export default (WrappedComponent) => {
  class HOC extends React.Component {
    componentDidMount () {
      if (!this.props.tasting && !fetching) {
        fetching = true
        this.props.getTasting(this.props.match.params.id)
          .then(() => {
            fetching = false
          })
          .catch(() => {
            fetching = false
          })
      }
    }
    render () {
      return <WrappedComponent {...this.props} />
    }
  }
  return compose(
    withLogin,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(HOC)
}
