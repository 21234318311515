import gql from 'graphql-tag'
import _format from 'date-fns/format'
import { EDIT_SECTION_SAVE_SUCCESS, EDIT_TYPES } from './edit'

export const GET_TASTINGS = 'api/GET_TASTINGS' // add state updates for these
export const GET_TASTINGS_SUCCESS = 'api/GET_TASTINGS_SUCCESS'
export const GET_TASTINGS_FAILURE = 'api/GET_TASTINGS_FAILURE' // add state updates for these

export const GET_TASTING = 'api/GET_TASTING' // add state updates for these
export const GET_TASTING_SUCCESS = 'api/GET_TASTING_SUCCESS'
export const GET_TASTING_FAILURE = 'api/GET_TASTING_FAILURE'

export const GET_ATTENDEES = 'api/GET_ATTENDEES'
export const GET_ATTENDEES_SUCCESS = 'api/GET_ATTENDEES_SUCCESS'
export const GET_ATTENDEES_FAILURE = 'api/GET_ATTENDEES_FAILURE'

export const TOGGLE_SHOW_SAMPLE_NAMES = 'TOGGLE_SHOW_SAMPLE_NAMES'

const dateFormat = date => _format(date, 'YYYY-MM-DD')

export const getTastingById = (tastingId, state) => {
  return state.tasting.details[tastingId]
}

export const getSampleById = (tastingId, sampleId, state) => {
  return state.tasting.details[tastingId].samples.find(sample => sample.id === sampleId)
}

export const getAttendeesById = (id, state) => {
  return state.tasting.details[id].participants
}

/* action creators */
export const toggleShowSampleNames = (tastingId) => {
  return {
    type: TOGGLE_SHOW_SAMPLE_NAMES,
    payload: {
      tastingId
    }
  }
}

export const getTastings = () => (dispatch, getState, client) => {
  dispatch({ type: GET_TASTINGS })
  const query = gql`
    {
      tastings {
        id,
        name,
        description
      }
    }
  `

  return client.query({ query })
    .then(({ data }) => dispatch({ type: GET_TASTINGS_SUCCESS, payload: data.tastings }))
    .catch(() => dispatch({ type: GET_TASTINGS_FAILURE }))
}

export const getTasting = id => (dispatch, getState, client) => {
  const query = gql`
    query tasting($id: ID!) {
      tasting(id: $id) {
        id,
        code,
        name,
        description,
        showResults,
        startDate,
        endDate,
        samples {
          id,
          tastingId,
          label,
          name,
          order,
          average
        },
        participants {
          id,
          name,
          profilePicture,
          notes {
            id,
            color,
            nose,
            taste,
            guess,
            rating,
            sample {
              name
            }
          }
        }
      }
    }
  `
  dispatch({ type: GET_TASTING })
  return client.query({ query, variables: { id } })
    .then(({ data }) => dispatch({ type: GET_TASTING_SUCCESS, payload: { id, details: data.tasting } }))
    .catch(err => {
      console.log(err)
      dispatch({ type: GET_TASTING_FAILURE })
    })
}

/* fetchers */
export const postSample = (sample, id, client) => {
  const mutation = gql`
    mutation createSample($tastingId: ID!, $label: String!, $name: String!, $order: Int!) {
      sample: createSample(tastingId: $tastingId, label: $label, name: $name, order: $order) {
        id,
        tastingId,
        label,
        name,
        order
      }
    }
  `
  const data = Object.assign({}, sample, {
    tastingId: id
  })
  return client.mutate({ mutation, variables: data })
    .then(({ data }) => data.sample)
}

export const putSample = (sample, client) => {
  const mutation = gql`
    mutation updateSample($id: ID!, $label: String!, $name: String!, $order: Int!) {
      sample: updateSample(id: $id, label: $label, name: $name, order: $order) {
        id,
        tastingId,
        label,
        name,
        order
      }
    }
  `
  return client.mutate({ mutation, variables: sample })
    .then(({ data }) => data.sample)
}

export const putTasting = (tasting, client) => {
  const mutation = gql`
    mutation updateTasting($id: ID!, $name: String!, $description: String!, $startDate: Date!, $endDate: Date!, $showResults: Boolean!) {
      tasting: updateTasting(id: $id, name: $name, description: $description, startDate: $startDate, endDate: $endDate, showResults: $showResults) {
        id,
        code,
        name,
        description,
        showResults,
        startDate,
        endDate
      }
    }
  `
  const data = Object.assign({}, tasting, {
    startDate: dateFormat(tasting.startDate),
    endDate: dateFormat(tasting.endDate)
  })
  return client.mutate({ mutation, variables: data })
    .then(({ data }) => data.tasting)
}

export const postTasting = (tasting, client) => {
  const mutation = gql`
    mutation createTasting($code: String!, $name: String!, $description: String!, $startDate: Date!, $endDate: Date!) {
      tasting: createTasting(code: $code, name: $name, description: $description, startDate: $startDate, endDate: $endDate) {
        id,
        code,
        name,
        description,
        showResults,
        startDate,
        endDate,
        samples {
          id
        },
        participants {
          id
        }
      }
    }
  `
  const data = Object.assign({}, tasting, {
    startDate: dateFormat(tasting.startDate),
    endDate: dateFormat(tasting.endDate)
  })
  return client.mutate({ mutation, variables: data })
    .then(({ data }) => data.tasting)
}

/* reducer */

const getUpdatedTastings = (state, payload) => {
  const { rtnData, sectionName, id } = payload
  if (EDIT_TYPES.NEW_TASTING === sectionName) {
    return {
      ...state,
      tastings: state.tastings.concat(rtnData),
      details: {
        ...state.details,
        [rtnData.id]: rtnData
      }
    }
  } else if (EDIT_TYPES.NEW_SAMPLE === sectionName) {
    const updatedTasting = Object.assign({}, state.details[id], { // todo: consider breaking sample out buy ID into seperate reducers
      samples: state.details[id].samples.concat(rtnData)
    })
    return {
      ...state,
      details: {
        ...state.details,
        [id]: updatedTasting
      }
    }
  } else if (EDIT_TYPES.TASTING_DETAILS === sectionName) {
    return {
      ...state,
      tastings: state.tastings.map(tasting => {
        if (tasting.id === id) {
          return rtnData
        }
        return tasting
      }),
      details: {
        ...state.details,
        [id]: {
          ...state.details[id],
          ...rtnData
        }
      }
    }
  } else if (EDIT_TYPES.SAMPLE_DETAILS === sectionName) {
    return {
      ...state,
      details: {
        ...state.details,
        [id]: {
          ...state.details[id], // this need to be the id of the tasting...
          samples: state.details[id].samples.map(sample => {
            if (sample.id === rtnData.id) {
              return rtnData
            }
            return sample
          })
        }
      }
    }
  }
}
const initialState = {
  tastings: [],
  details: {}
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TASTINGS_SUCCESS:
      return Object.assign({}, state, {
        tastings: payload
      })
    case GET_TASTING_SUCCESS:
      const details = Object.assign({}, state.details, { [payload.id]: payload.details })
      return Object.assign({}, state, {
        details
      })
    case EDIT_SECTION_SAVE_SUCCESS:
      return getUpdatedTastings(state, payload)
    case TOGGLE_SHOW_SAMPLE_NAMES:
      return {
        ...state,
        details: {
          ...state.details,
          [payload.tastingId]: {
            ...state.details[payload.tastingId],
            showSampleNames: !state.details[payload.tastingId].showSampleNames
          }
        }
      }
    default:
      return state
  }
}
