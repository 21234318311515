import React from 'react'
import Header from '../Components/Header'
import EditContainer from '../Components/EditContainer'
import SubPage from '../Components/SubPage'
import { useQuery } from 'react-apollo-hooks'
import { PARTICIPANT_DETAIL_QUERY } from '../graphql/queries'
import Notes from '../Components/Participant/Notes'
import Tastings from '../Components/Participant/Tastings'

const Participant = (props) => {
  const { data, error, loading } = useQuery(PARTICIPANT_DETAIL_QUERY, { variables: { id: props.match.params.id } })
  if (error) {
    return <span>errored</span>
  }
  if (loading) {
    return <span>Loading...</span>
  }
  const { user } = data
  const participant = user
  return (
    <div>
      <Header detail title={participant ? participant.name : ''} />
      <SubPage tabs={['Tastings', 'Notes']} pageName='participant'>
        <Tastings participant={participant} />
        <Notes participant={participant} />
      </SubPage>
      <EditContainer />
    </div>
  )
}

export default Participant
