import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Header from '../Components/Header'
import Hero from '../Components/Hero'
import EditContainer from '../Components/EditContainer'
import { EDIT_TYPES, editSection as editSectionAction } from '../store/edit'
import TastingCards from '../Components/TastingCards'

const styles = theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6
  }
})

class Home extends React.Component {
  render () {
    const { classes, createTasting } = this.props

    return (
      <React.Fragment>
        <EditContainer />
        <CssBaseline />
        <Header />
        <main>
          <Hero
            title={'Speak Easy WI'}
            secondary={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'}
            primaryActionText={'Create Tasting'}
            primaryOnClick={createTasting}
          />
          <TastingCards />
        </main>
        {/* Footer */}
        <footer className={classes.footer}>
          <Typography variant='h6' align='center' gutterBottom>Drink on folks</Typography>
          <Typography variant='subtitle1' align='center' color='textSecondary' component='p'>Math Teacher: "If I have 5 bottles in one hand and 6 in the other hand, what do I have?" Student: "A drinking problem."</Typography>
        </footer>
        {/* End footer */}
      </React.Fragment>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => ({
  createTasting: () => dispatch(editSectionAction(EDIT_TYPES.NEW_TASTING))
})
export default connect(null, mapDispatchToProps)(withStyles(styles)(Home))
