/* global sessionStorage */
import ApolloClient from 'apollo-boost'

const client = new ApolloClient({
  uri: '/graphql',
  request: async operation => {
    const accessToken = sessionStorage.getItem('accessToken')
    operation.setContext({
      headers: {
        'access_token': accessToken
      }
    })
  }
})

export default client
