import React from 'react'
import Detail from '../Components/Detail'
import Header from '../Components/Header'
import EditContainer from '../Components/EditContainer'
import withTasting from '../Components/withTasting'

class Tasting extends React.Component {
  render () {
    const { tasting } = this.props
    return (
      <div>
        <Header detail title={tasting ? tasting.name : ''} />
        <EditContainer />
        <Detail />
      </div>
    )
  }
}

export default withTasting(Tasting)
