import React from 'react'
import Sample from './Sample'
import CardContainer from './CardContainer'
import withTasting from './withTasting'
import Hero from './Hero'
import { connect } from 'react-redux'
import { editSection as editSectionAction, EDIT_TYPES } from '../store/edit'
import { toggleShowSampleNames } from '../store/tasting'
import Switch from '@material-ui/core/Switch'

class Samples extends React.Component {
  render () {
    const { tasting, editSection, toggleShowSampleNames } = this.props
    const { samples } = tasting
    if (!samples) return null
    return (
      <div>
        <Hero
          title={'Samples'}
          secondary={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
          primaryActionText={'Add Sample'}
          primaryOnClick={editSection}
          secondaryComponent={
            <Switch
              checked={tasting.showSampleNames}
              onChange={toggleShowSampleNames}
              value='showSampleNames'
            />
          }
        />
        <CardContainer>
          {samples.map((sample, index) => <Sample key={index} sample={sample} tastingId={tasting.id} showSampleNames={tasting.showSampleNames} />)}
        </CardContainer>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  editSection: () => dispatch(editSectionAction(EDIT_TYPES.NEW_SAMPLE, ownProps.tasting.id)),
  toggleShowSampleNames: () => dispatch(toggleShowSampleNames(ownProps.tasting.id))
})

export default withTasting(connect(null, mapDispatchToProps)(Samples))
