import { connect } from 'react-redux'

import React from 'react'

const mapStateToProps = (state, ownProps) => ({
  user: state.facebook.user
})

const mapDispatchToProps = (dispatch) => ({
})

export default (WrappedComponent) => {
  class HOC extends React.Component {
    render () {
      // if no user is present dont return
      if (!this.props.user.accessToken) return null
      return <WrappedComponent {...this.props} />
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(HOC)
}
