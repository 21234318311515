import React from 'react'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getDateForPicker, getDateFromPicker } from '../../utils/dates'
import withEdit from './withEdit'

class TastingDetails extends React.Component {
  render () {
    const { editedData, updateEditSection } = this.props

    return (
      <form noValidate>
        <TextField
          id='name'
          value={editedData.name}
          onChange={(e) => updateEditSection('name', e.target.value)}
          label='Name'
          type='text'
          fullWidth
        />
        <TextField
          id='description'
          value={editedData.description}
          onChange={(e) => updateEditSection('description', e.target.value)}
          label='Description'
          type='text'
          fullWidth
        />
        <TextField
          id='startDate'
          label='Start Date'
          type='date'
          value={getDateForPicker(editedData.startDate)}
          onChange={(e) => updateEditSection('startDate', getDateFromPicker(e.target.value))}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          id='endDate'
          label='End Date'
          type='date'
          value={getDateForPicker(editedData.endDate)}
          onChange={(e) => updateEditSection('endDate', getDateFromPicker(e.target.value))}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          id='code'
          label='Invite Code'
          type='text'
          value={editedData.code}
          onChange={(e) => updateEditSection('code', e.target.value)}
          fullWidth
        />
        <FormControlLabel
          control={
            <Switch
              checked={editedData.showResults}
              onChange={(e) => updateEditSection('showResults', e.target.checked)}
              value='showResults'
            />
          }
          label='Show Results'
        />
      </form>
    )
  }
}

export default withEdit(TastingDetails)
