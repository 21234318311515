import React from 'react'
import TextField from '@material-ui/core/TextField'
import withEdit from './withEdit'

class Sample extends React.Component {
  render () {
    const { editedData, updateEditSection } = this.props

    return (
      <form noValidate>
        <TextField
          autoFocus
          id='name'
          value={editedData.name}
          onChange={(e) => updateEditSection('name', e.target.value)}
          label='name'
          type='text'
          fullWidth
        />
        <TextField
          id='label'
          value={editedData.label}
          onChange={(e) => updateEditSection('label', e.target.value)}
          label='label'
          type='text'
          fullWidth
        />
        <TextField
          id='order'
          value={editedData.order}
          onChange={(e) => updateEditSection('order', parseInt(e.target.value))}
          label='order'
          type='number'
          fullWidth
        />
      </form>
    )
  }
}

export default withEdit(Sample)
