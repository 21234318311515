import React from 'react'
import Hero from '../Hero'
import NoteTable from './NoteTable'

const Notes = ({ sample }) => {
  const { name, id, average, label, order, notes } = sample

  return (
    <div>
      <Hero
        title={name}
        secondary={`id: ${id} average: ${average} label: "${label}" order: ${order}`} />
      <NoteTable data={notes} />
    </div>)
}

export default Notes
