import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  layout: {
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `${theme.spacing.unit * 8}px ${theme.spacing.unit * 2}px`
  }
})

class CardContainer extends React.Component {
  render () {
    const { classes, children } = this.props

    return (
      <div className={classes.layout}>
        <Grid container spacing={40}>{children}</Grid>
      </div>
    )
  }
}

export default withStyles(styles)(CardContainer)
