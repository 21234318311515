import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withRouter } from 'react-router-dom'

const styles = {
  root: {
    flexGrow: 1
  }
}

class SubPage extends React.Component {
  constructor (props) {
    super(props)
    const { match } = this.props
    const { navItem = 0 } = match.params

    this.state = { navItem: parseInt(navItem) }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event, value) {
    this.props.history.replace(`/${this.props.pageName}/${this.props.match.params.id}/${value}`)
    this.setState({ navItem: value })
  }

  render () {
    const { classes, children, tabs } = this.props
    const { navItem } = this.state

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Tabs
            value={navItem}
            onChange={this.handleChange}
            indicatorColor='primary'
            textColor='primary'
            centered
            fullWidth >
            {tabs.map((tab, index) => <Tab label={tab} key={index} />)}
          </Tabs>
        </Paper>
        {children[navItem]}
      </React.Fragment>
    )
  }
}

SubPage.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  pageName: PropTypes.string.isRequired
}

export default withRouter(withStyles(styles)(SubPage))
